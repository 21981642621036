import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { MEDIA_MOBILE } from "../const"
import Layout from "../components/layout"
import Metadata from "../components/metadata"
import Heading2 from "../components/heading2"
import typography from "../utils/typography"

import { WorksQuery } from "../../types/graphql-types"

interface BulkLinkProps {
  url: string
}

const BulkLink: React.FC<BulkLinkProps> = ({ url }) => (
  <BulkLinkWrapper>
    <a href={url} target="_blank" rel="noreferrer">
      <Label>♪ 配信･販売中</Label>
      <Services>サービス一覧へ →</Services>
    </a>
  </BulkLinkWrapper>
)

interface WorksProps {
  data: WorksQuery
  location: Location
}

const Works: React.FC<WorksProps> = ({ data, location }) => (
  <Layout>
    <Metadata
      title="Works"
      pagePath={location.pathname}
      description="ぁゃぴの今までの作品一覧"
    />
    <Wrapper>
      <Heading2>Works</Heading2>
      <Release>
        <Title>
          3人目の彼女
          <br />
          「チャラいから」
        </Title>
        <BoxList>
          <Jacket>
            <Img
              fluid={data.charaikara.childImageSharp.fluid}
              alt="3人目の彼女「チャラいから」ジャケット"
            />
          </Jacket>
          <BulkLink url="https://lnkfi.re/charaikara" />
        </BoxList>
        <p>2020.06.09 配信</p>
        <TrackList>
          <li>チャラいから</li>
          <li>エアー読んでよ</li>
          <li>ギャルにギター</li>
        </TrackList>
        <p>全曲でヴォーカル、ギター、作詞、作曲、編曲、Mix</p>
      </Release>
      <Release>
        <Title>
          V.A.
          <br />
          「聖地☆秋葉原バトルロワイヤル」
        </Title>
        <BoxList>
          <Jacket>
            <Img
              fluid={data.akiba.childImageSharp.fluid}
              alt="V.A.「聖地☆秋葉原バトルロワイヤル」ジャケット"
            />
          </Jacket>
          <BulkLink url="https://lnkfi.re/saigo" />
        </BoxList>
        <p>
          2010.05.05 発売
          <br />
          MAID-1001 Maid In Hell Records / All Creative Enterteinment Inc.
        </p>
        <TrackList start={14}>
          <li>3人目の彼女「最期の彼女」</li>
        </TrackList>
        <p>14: ヴォーカル、ギター、作詞、作曲、編曲、Mix</p>
      </Release>
      <Release>
        <Title>
          竹の上のポニョ feat.え る も<br />
          「「ここにいるよ。」」
        </Title>
        <BoxList>
          <Jacket>
            <Img
              fluid={data.takeponyo.childImageSharp.fluid}
              alt="竹の上のポニョ feat.え る も「ここにいるよ。」ジャケット"
            />
          </Jacket>
        </BoxList>
        <p>2009.07.22 発売</p>
        <TrackList>
          <li>「ここにいるよ。」-intro-</li>
          <li>DON'T YOU LOVE ME?</li>
          <li>PASSION</li>
          <li>さらば、カマキリ夫人[Rehearsal version]</li>
          <li>AMORE AMORE</li>
          <li>Coffee & Love Sick</li>
          <li>ENDLESSWALTZ</li>
        </TrackList>
        <p>
          1: Mix
          <br />
          2, 3, 4, 5, 6, 7: ヴォーカル、編曲、Mix
        </p>
      </Release>
      <Release>
        <Title>
          ぁゃぴ (階段ライト)
          <br />
          「ひかないで、後ろ髪」
        </Title>
        <BoxList>
          <BulkLink url="https://lnkfi.re/hikanaide" />
        </BoxList>
        <p>2009.04.09 発売</p>
        <TrackList>
          <li>ひかないで、後ろ髪</li>
        </TrackList>
        <p>ヴォーカル、作詞、作曲、編曲、Mix</p>
      </Release>
      <Release>
        <Title>
          ぁゃぴ (階段ライト)
          <br />
          「D.D.R. -だれでも・だいすき・レボリューション-」
        </Title>
        <BoxList>
          <BulkLink url="https://lnkfi.re/ddr" />
        </BoxList>
        <p>2009.01.25 発売</p>
        <TrackList>
          <li>D.D.R. -だれでも・だいすき・レボリューション-</li>
        </TrackList>
        <p>ヴォーカル、作詞、作曲、編曲、Mix</p>
      </Release>
      <Release>
        <Title>
          ぁゃぴ (階段ライト)
          <br />
          「ムーンライト多摩川」
        </Title>
        <BoxList>
          <BulkLink url="https://lnkfi.re/moontama" />
        </BoxList>
        <p>2008.07.21 発売</p>
        <TrackList>
          <li>ムーンライト多摩川</li>
        </TrackList>
        <p>ヴォーカル、ギター、作詞、作曲、編曲、Mix</p>
      </Release>
      <Release>
        <Title>
          ぁゃぴ (階段ライト)
          <br />
          「意味不明」
        </Title>
        <BoxList>
          <Jacket>
            <Img
              fluid={data.imifu.childImageSharp.fluid}
              alt="ぁゃぴ (階段ライト)「意味不明」ジャケット"
            />
          </Jacket>
          <BulkLink url="https://lnkfi.re/imifu" />
        </BoxList>
        <p>2008.06.22 発売</p>
        <TrackList>
          <li>帰りたくないダンスホール</li>
          <li>意味不明</li>
          <li>踏切</li>
        </TrackList>
        <p>ヴォーカル、ギター、作詞、作曲、編曲、Mix</p>
      </Release>
      <Release>
        <Title>
          階段ライト
          <br />
          「信号機の中に一人」
        </Title>
        <BoxList>
          <Jacket>
            <Img
              fluid={data.shingouki.childImageSharp.fluid}
              alt="階段ライト「信号機の中に一人」ジャケット"
            />
          </Jacket>
          <BulkLink url="https://lnkfi.re/trafficlight" />
        </BoxList>
        <p>
          2007.12.17 発売
          <br /> PMR-001 PMR1503
        </p>
        <TrackList>
          <li>ビートルズマニア</li>
          <li>雪面反射鏡度</li>
          <li>―</li>
        </TrackList>
        <p>
          1: ヴォーカル、ギター、作詞、作曲、編曲
          <br />
          2, 3: ヴォーカル、ギター、作詞、作曲、編曲、Mix
        </p>
      </Release>
      <Release>
        <Title>
          階段ライト
          <br />
          「おから」
        </Title>
        <BoxList>
          <Jacket>
            <Img
              fluid={data.okara.childImageSharp.fluid}
              alt="階段ライト「おから」ジャケット"
            />
          </Jacket>
          <BulkLink url="https://lnkfi.re/okr" />
        </BoxList>
        <p>
          2006.12.06 発売
          <br /> OUKL-0003 others underground
        </p>
        <TrackList>
          <li>おから</li>
          <li>結晶相転移論</li>
          <li>幽霊と話す</li>
        </TrackList>
        <p>
          1: ヴォーカル、ギター、作詞、編曲、Mix
          <br />
          2, 3: ヴォーカル、ギター、作詞、作曲、編曲、Mix
        </p>
      </Release>
      <Release>
        <Title>
          階段ライト
          <br />
          「高いところ」
        </Title>
        <BoxList>
          <Jacket>
            <Img
              fluid={data.takai.childImageSharp.fluid}
              alt="階段ライト「高いところ」ジャケット"
            />
          </Jacket>
          <BulkLink url="https://lnkfi.re/takaitokoro" />
        </BoxList>
        <p>
          2006.11.01 発売
          <br /> OUKL-0002 others underground
        </p>
        <TrackList>
          <li>高いところ</li>
          <li>烏</li>
          <li>ボーカル・ブース・ボーナス</li>
        </TrackList>
        <p>
          1: ヴォーカル、ギター、作詞、編曲、Mix
          <br />
          2: ヴォーカル、ギター、作詞、作曲、編曲、Mix
        </p>
      </Release>
      <Release>
        <Title>
          階段ライト
          <br />
          「凄い勢い」
        </Title>
        <BoxList>
          <Jacket>
            <Img
              fluid={data.sugoi.childImageSharp.fluid}
              alt="階段ライト「凄い勢い」ジャケット"
            />
          </Jacket>
          <BulkLink url="https://lnkfi.re/sugoi" />
        </BoxList>
        <p>
          2006.10.04 発売
          <br /> OUKL-0001 others underground
        </p>
        <TrackList>
          <li>凄い勢い</li>
          <li>真夜中のボーナストラック</li>
        </TrackList>
        <p>1: ヴォーカル、ギター、作詞、作曲、編曲、Mix</p>
      </Release>
      <Release>
        <Title>
          階段ライト
          <br />
          「セルリアンタワー修復作業」
        </Title>
        <BoxList>
          <Jacket>
            <Img
              fluid={data.celshufuku.childImageSharp.fluid}
              alt="階段ライト「セルリアンタワー修復作業」ジャケット"
            />
          </Jacket>
          <BulkLink url="https://lnkfi.re/cerulean" />
        </BoxList>
        <p>
          2006.03.29 発売
          <br /> LSOU-0001 others underground
        </p>
        <TrackList>
          <li>適当な音</li>
          <li>00001</li>
          <li>ふぁみれど～バナナフィッシュ～</li>
          <li>明治時代</li>
          <li>「…フロォティングサイバァスカィ」</li>
          <li>携帯放置アワー</li>
          <li>宮益坂の憂鬱</li>
          <li>君が死んだら</li>
        </TrackList>
        <p>
          1: 作曲、編曲、Mix
          <br />
          2, 3, 4, 5, 6, 7, 8: ヴォーカル、ギター、作詞、作曲、編曲、Mix
        </p>
      </Release>
      <Release>
        <Title>
          階段ライト
          <br />
          「企画倒れの遊園地 リニューアルオープン」
        </Title>
        <BoxList>
          <Jacket>
            <Img
              fluid={data.kikakurenewal.childImageSharp.fluid}
              alt="階段ライト「企画倒れの遊園地 リニューアルオープン」ジャケット"
            />
          </Jacket>
          <BulkLink url="https://lnkfi.re/kikaku" />
        </BoxList>
        <p>
          2006.03.29 発売
          <br /> LSOU-0002 others underground
        </p>
        <TrackList>
          <li>キャンサー</li>
          <li>さくら</li>
          <li>頭痛と夕陽</li>
          <li>赤いユイ</li>
          <li>02-NAIL-</li>
          <li>名称未設定</li>
          <li>因数分解M3</li>
          <li>ドライアイスな落下速度</li>
          <li>01</li>
        </TrackList>
        <p>
          1, 3, 4, 5, 6, 8, 9: ヴォーカル、ギター、作詞、作曲、編曲、Mix
          <br />
          2: ヴォーカル、ギター、キーボード、作詞、作曲、編曲、Mix
          <br />
          7: ヴォーカル、ギター、ピアノ、作詞、作曲、編曲、Mix
        </p>
      </Release>
      <Release>
        <Title>
          ドゥードゥースクラッチぁゃぴ
          <br />
          「残念な雪のチョコレート」
        </Title>
        <p>2005.02.14 配布</p>
        <TrackList>
          <li>残念な雪のチョコレート</li>
        </TrackList>
        <p>ヴォーカル、ギター、作詞、作曲、編曲、Mix</p>
      </Release>
      <Release>
        <Title>
          ドゥードゥースクラッチぁゃぴ
          <br />
          「optical optima」
        </Title>
        <BoxList>
          <Jacket>
            <Img
              fluid={data.optima.childImageSharp.fluid}
              alt="ドゥードゥースクラッチぁゃぴ「optical optima」ジャケット"
            />
          </Jacket>
          <BulkLink url="https://lnkfi.re/optima" />
        </BoxList>
        <p>2004.12.24発売 GAC-001</p>
        <TrackList>
          <li>大容量記憶装置</li>
          <li>―終了―</li>
          <li>Temporally depression</li>
          <li>%age</li>
          <li>JR渋谷駅南改札ハードル競走</li>
          <li>リスヵなんて言葉自体忘れてたって感じの惰眠dummy(起床したver.)</li>
          <li>残念な雪</li>
          <li>ドラマチックなみドルテンポ</li>
          <li>ごきげん渋谷</li>
        </TrackList>
        <p>
          1, 2, 6, 9: ヴォーカル、ギター、作詞、作曲、編曲、Mix
          <br />
          3: ヴォーカル、ギター、ベース、作詞、作曲、編曲、Mix
          <br />
          4, 5, 7: ヴォーカル、作詞、作曲、編曲、Mix
          <br />
          8: ヴォーカル、ギター、ピアノ、作詞、作曲、編曲、Mix
        </p>
      </Release>
      <Release>
        <Title>
          ドゥードゥースクラッチぁゃぴ
          <br />
          「幻蝕GAL、、コテで逝くべ!?」
        </Title>
        <p>2004.10.26 配布</p>

        <h4>通常盤</h4>
        <BoxList>
          <Jacket>
            <Img
              fluid={data.genshokuA.childImageSharp.fluid}
              alt="ドゥードゥースクラッチぁゃぴ「幻蝕GAL、、コテで逝くべ!?」通常盤ジャケット"
            />
          </Jacket>
        </BoxList>
        <TrackList>
          <li>コメントC</li>
          <li>幻蝕GAL、、コテで逝くべ!?</li>
        </TrackList>

        <h4>会場盤</h4>
        <BoxList>
          <Jacket>
            <Img
              fluid={data.genshokuB.childImageSharp.fluid}
              alt="ドゥードゥースクラッチぁゃぴ「幻蝕GAL、、コテで逝くべ!?」会場盤ジャケット"
            />
          </Jacket>
        </BoxList>
        <TrackList>
          <li>コメントB</li>
          <li>幻蝕GAL、、コテで逝くべ!?</li>
          <li>ボーナストラック</li>
        </TrackList>
        <p>2: ヴォーカル、ギター、作詞、編曲、Mix</p>
      </Release>
      <Release>
        <Title>
          Raptlyfact
          <br />
          「TEENAGE ROCK EXPLOSION」
        </Title>
        <BoxList>
          <Jacket>
            <Img
              fluid={data.trex.childImageSharp.fluid}
              alt="Raptlyfact「TEENAGE ROCK EXPLOSION」ジャケット"
            />
          </Jacket>
        </BoxList>
        <p>2004.9.17発売</p>

        <TrackList start={13}>
          <li>FloorTrain(Doo Doo Scratch Mix)</li>
        </TrackList>

        <p>13: ロックバンド「Raptlyfact」の曲のリミックスで参加</p>
      </Release>
      <Release>
        <Title>
          ドゥードゥースクラッチぁゃぴ
          <br />
          「ぁゃぴの究極のォマヶディスク」
        </Title>
        <BoxList>
          <Jacket>
            <Img
              fluid={data.omake.childImageSharp.fluid}
              alt="ドゥードゥースクラッチぁゃぴ「ぁゃぴの究極のォマヶディスク」ジャケット"
            />
          </Jacket>
        </BoxList>
        <p>2004.03.27 配布 </p>
        <TrackList>
          <li>Temporally depression(demo ver.)</li>
          <li>僕の歌を総て君にやる(like an avex mix)</li>
        </TrackList>
        <p>
          1: ヴォーカル、ギター、作詞、作曲、編曲、Mix
          <br />
          2: ヴォーカル、作詞、作曲、編曲、Mix
        </p>
      </Release>
      <Release>
        <Title>
          ドゥードゥースクラッチぁゃぴ
          <br />
          「卒業したってどうせ電波。さよならAGHS(号泣)」
        </Title>
        <BoxList>
          <Jacket>
            <Img
              fluid={data.gra.childImageSharp.fluid}
              alt="ドゥードゥースクラッチぁゃぴ「卒業したってどうせ電波。さよならAGHS(号泣)」ジャケット"
            />
          </Jacket>
        </BoxList>
        <p>2004.03.11 発売</p>
        <p>Non Stop Techno Mix</p>
      </Release>
      <Release>
        <Title>
          ドゥードゥースクラッチぁゃぴ
          <br />
          「ネオ渋谷系の不思議チャソ 2nd Edition」
        </Title>
        <BoxList>
          <Jacket>
            <Img
              fluid={data.neo2.childImageSharp.fluid}
              alt="ドゥードゥースクラッチぁゃぴ「ネオ渋谷系の不思議チャソ 2nd Edition」ジャケット"
            />
          </Jacket>
          <BulkLink url="https://lnkfi.re/fushigi" />
        </BoxList>
        <p>2004.03.01発売</p>
        <TrackList>
          <li>白装束の調べ(今度はマヂにRetake ver.)</li>
          <li>2003autumn→autism(これ余計ヘタになってねぇ?ver.)</li>
          <li>不思議チャソの逆襲(くっきりver.)</li>
          <li>ネオ渋谷系のあなた(ミラクルハードピコピコver.)</li>
          <li>「…フロォティングサイバァスカィ」(Remix version)</li>
          <li>名曲(がんがったver.)</li>
        </TrackList>
        <p>
          1, 2, 4, 6: ヴォーカル、ギター、作詞、作曲、編曲、Mix
          <br />
          3, 5: ヴォーカル、作詞、作曲、編曲、Mix
        </p>
      </Release>
      <Release>
        <Title>
          ドゥードゥースクラッチぁゃぴ
          <br />
          「着メロが鳴る前の僕の行動」
        </Title>
        <BoxList>
          <Jacket>
            <Img
              fluid={data.chaku.childImageSharp.fluid}
              alt="ドゥードゥースクラッチぁゃぴ「着メロが鳴る前の僕の行動」ジャケット"
            />
          </Jacket>
          <BulkLink url="https://lnkfi.re/chakumelo" />
        </BoxList>
        <p>2003.12.20発売</p>
        <TrackList>
          <li>ベランダ</li>
          <li>リスヵって言葉自体忘れてたって感じの惰眠dummy</li>
          <li>ふぁみれど～バナナフィッシュ～メルヘンューロビィトバァジョン</li>
        </TrackList>
        <p>
          1, 2: ヴォーカル、ギター、作詞、作曲、編曲、Mix
          <br />
          3: ヴォーカル、作詞、作曲、編曲、Mix
        </p>
      </Release>
      <Release>
        <Title>
          ドゥードゥースクラッチぁゃぴ
          <br />
          「2003autumn→autism...」
        </Title>
        <BoxList>
          <Jacket>
            <Img
              fluid={data.autumn.childImageSharp.fluid}
              alt="ドゥードゥースクラッチぁゃぴ「2003autumn→autism...」ジャケット"
            />
          </Jacket>
        </BoxList>
        <p>2003.09.12発売</p>
        <TrackList>
          <li>2003autumn→autism...</li>
        </TrackList>
        <p>1: ヴォーカル、ギター、作詞、作曲、編曲、Mix</p>
      </Release>
      <Release>
        <Title>
          ドゥードゥースクラッチぁゃぴ
          <br />
          「ネオ渋谷系の不思議チャソ」
        </Title>
        <BoxList>
          <Jacket>
            <Img
              fluid={data.neo1.childImageSharp.fluid}
              alt="ドゥードゥースクラッチぁゃぴ「ネオ渋谷系の不思議チャソ」ジャケット"
            />
          </Jacket>
        </BoxList>
        <p>2003.08.14発売</p>
        <TrackList>
          <li>白装束の調べ(Remix version)</li>
          <li>カナリ</li>
          <li>不思議チャソの逆襲</li>
          <li>ネオ渋谷系のあなた</li>
          <li>「…フロォティングサイバァスカィ」(Remix version)</li>
          <li>名曲</li>
        </TrackList>
        <p>
          1, 2, 4, 6: ヴォーカル、ギター、作詞、作曲、編曲、Mix
          <br />
          3, 5: ヴォーカル、作詞、作曲、編曲、Mix
        </p>
      </Release>
      <Release>
        <Title>
          ドゥードゥースクラッチぁゃぴ
          <br />
          「HR304バレーボール応援歌」
        </Title>
        <BoxList>
          <Jacket>
            <Img
              fluid={data.hr304.childImageSharp.fluid}
              alt="ドゥードゥースクラッチぁゃぴ「HR304バレーボール応援歌」ジャケット"
            />
          </Jacket>
        </BoxList>
        <p>2003.06.06 配布</p>
        <TrackList>
          <li>HR304バレーボール応援歌</li>
          <li>白装束の調べ</li>
        </TrackList>
        <p>全曲 ヴォーカル、ギター、作詞、作曲、編曲、Mix</p>
      </Release>
      <Release>
        <Title>
          レヴェリー
          <br />
          「僕の彼女はグラビアアイドル」
        </Title>
        <p>2003-03-23 配布</p>
        <TrackList>
          <li>生きる屍症候群</li>
          <li>醜い鶩の子</li>
          <li>僕の彼女はグラビアアイドル</li>
        </TrackList>
        <p>全曲 ギター、作曲、編曲、Mix</p>
      </Release>
      <Release>
        <Title>
          階段ライト
          <br />
          「セルリアンタワー破壊衝動」
        </Title>
        <p>2003.03.08 発売</p>
        <TrackList>
          <li>適当な音</li>
          <li>00001</li>
          <li>ふぁみれど～バナナフィッシュ～</li>
          <li>明治時代</li>
          <li>「…フロォティングサイバァスカィ」</li>
          <li>携帯放置アワー</li>
          <li>宮益坂の憂鬱</li>
          <li>君が死んだら</li>
        </TrackList>
        <p>
          1: 作曲、編曲、Mix
          <br />
          2, 3, 4, 5, 6, 7, 8: ヴォーカル、ギター、作詞、作曲、編曲、Mix
        </p>
      </Release>
      <Release>
        <Title>
          階段ライト
          <br />
          「ふぁみれど～バナナフィッシュ～」
        </Title>
        <p>2002-12-29 発売</p>
        <TrackList>
          <li>ふぁみれど～バナナフィッシュ～</li>
          <li>「渋谷センター街のロッテリアの上にあるカラオケでふぁみれど」</li>
        </TrackList>
        <p>
          1: ヴォーカル、ギター、作詞、作曲、編曲、Mix
          <br />
          2: ヴォーカル、作詞、作曲、編曲、Mix
        </p>
      </Release>
      <Release>
        <Title>
          階段ライト
          <br />
          「宮益坂の憂鬱」
        </Title>
        <p>2002-09-22 発売</p>
        <TrackList>
          <li>ハイパーミラクルロマンス～オールナイトたまプラーザ～</li>
          <li>宮益坂の憂鬱</li>
          <li>終わり</li>
        </TrackList>
        <p>2: ヴォーカル、ギター、作詞、作曲、編曲、Mix</p>
      </Release>
      <Release>
        <Title>
          階段ライト
          <br />
          「企画倒れの遊園地」
        </Title>
        <p>2002-03-21 発売</p>
        <TrackList>
          <li>キャンサー</li>
          <li>さくら</li>
          <li>頭痛と夕陽</li>
          <li>赤いユイ</li>
          <li>02-NAIL-</li>
          <li>名称未設定</li>
          <li>因数分解M3</li>
          <li>ドライアイスな落下速度</li>
          <li>01</li>
        </TrackList>
        <p>
          1, 3, 4, 5, 6, 8, 9: ヴォーカル、ギター、作詞、作曲、編曲、Mix
          <br />
          2: ヴォーカル、ギター、キーボード、作詞、作曲、編曲、Mix
          <br />
          7: ヴォーカル、ギター、ピアノ、作詞、作曲、編曲、Mix
        </p>
      </Release>
      <Release>
        <Title>
          階段ライト
          <br />
          「空を失う期待感」
        </Title>
        <p>2002/02/17 配布</p>
        <TrackList>
          <li>キャンサー</li>
          <li>赤いユイ</li>
          <li>因数分解M3</li>
          <li>赤いユイ(2002 Burst ver.)</li>
          <li>名称未設定(助けてアスカver.)</li>
        </TrackList>
        <p>
          1, 2, 3: ヴォーカル、ギター、作詞、作曲、編曲、Mix
          <br />
          4: ピアノ、作詞、作曲、編曲、Mix
          <br />
          5: ギター、作詞、作曲、編曲、Mix
        </p>
      </Release>
      <Release>
        <Title>
          階段ライト
          <br />
          「プルテウス幼生xp(ぺけぽん)」
        </Title>
        <p>2001-12-23 配布</p>
        <TrackList>
          <li>名称未設定</li>
          <li>ムーンライト伝説Speed Star Crime of Visual Shake ver.</li>
        </TrackList>
        <p>
          1: ヴォーカル、ギター、作詞、作曲、編曲、Mix
          <br />
          2: ヴォーカル、ギター、編曲、Mix
        </p>
      </Release>
    </Wrapper>
  </Layout>
)

export default Works

export const query = graphql`
  query Works {
    charaikara: file(relativePath: { eq: "jake/charaikara.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    akiba: file(relativePath: { eq: "jake/akiba.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    takeponyo: file(relativePath: { eq: "jake/takeponyo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imifu: file(relativePath: { eq: "jake/imifu.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    shingouki: file(relativePath: { eq: "jake/shingouki.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    okara: file(relativePath: { eq: "jake/okara.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    takai: file(relativePath: { eq: "jake/takai.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sugoi: file(relativePath: { eq: "jake/sugoi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    celshufuku: file(relativePath: { eq: "jake/cel-shufuku.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kikakurenewal: file(relativePath: { eq: "jake/kikaku-renewal.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    optima: file(relativePath: { eq: "jake/optical.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    genshokuA: file(relativePath: { eq: "jake/gensyoku-a.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    genshokuB: file(relativePath: { eq: "jake/gensyoku-b.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    trex: file(relativePath: { eq: "jake/trex.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    omake: file(relativePath: { eq: "jake/omake.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    gra: file(relativePath: { eq: "jake/gra.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    neo2: file(relativePath: { eq: "jake/neo2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    chaku: file(relativePath: { eq: "jake/chaku.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    autumn: file(relativePath: { eq: "jake/autumn.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    neo1: file(relativePath: { eq: "jake/neo1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hr304: file(relativePath: { eq: "jake/304.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Wrapper = styled.div`
  box-sizing: border-box;
  max-width: 40em;
  margin: 2rem auto;

  @media (${MEDIA_MOBILE}) {
    width: 100%;
    padding: 0 20px;
    padding-top: 0;
  }
`
const Release = styled.div`
  padding: 32px 0;
  border-bottom: 1px solid #666;

  &:first-of-type {
    border-top: 1px solid #666;
  }

  ${typography.toString()}

  @media (${MEDIA_MOBILE}) {
    > h3 {
      font-size: 1.3rem;
    }
  }

  > p {
    line-height: 1.6;

    &:last-child {
      margin-bottom: 0;
    }
  }
`
const Title = styled.h3`
  /* font-size: 1.6em;
  line-height: 1.8;
  margin-bottom: 20px; */
`
const BoxList = styled.div`
  display: flex;
  max-width: 360px;
  margin-bottom: 1rem;
`
const Jacket = styled.div`
  flex: 0;
  flex-basis: 140px;
  display: block;
  width: 140px;
  border: 1px solid #ccc;
  margin-right: 0.7rem;
  overflow: hidden;
`
const BulkLinkWrapper = styled.div`
  flex: 1 0;
  max-width: 200px;

  > a {
    display: block;
    padding: 6px 10px;
    line-height: 1.6;
    background-color: #000;
    color: #fff;

    &:hover {
      color: #ddd;
    }
  }
`
const Label = styled.div`
  text-align: left;
  font-size: 1rem;
`
const Services = styled.div`
  font-size: 0.7rem;
  text-align: right;
`
const TrackList = styled.ol`
  list-style-type: decimal;

  > li {
    margin-left: 1rem;
  }
`
